import { Component } from '@angular/core';

@Component({
  selector: 'app-branding',
  template: `
    <div class="branding">
      <a href="/">
        <img src="./assets/images/logos/logo.png" class="align-middle mb-2" alt="logo" style="width: 70%; object-fit: cover;" />
      </a>
    </div>
  `
})
export class BrandingComponent {
  constructor() {}
}
