import { UserRole } from 'src/app/core/auth/data/auth.model';
import { NavItem } from './nav-item/nav-item';

export const CustomerSidebarNavItems: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '/customer-dashboard',
    roles: [UserRole.Customer]
  },
  {
    displayName: 'Checks',
    iconName: 'checkup-list',
    route: '/checks',
    roles: [UserRole.Customer]
  }
];
