import { NgModule } from '@angular/core';
import { DefaultDataServiceConfig, EntityDataModule, EntityDataService, HttpUrlGenerator } from '@ngrx/data';
import * as fromEntity from './entity';
import * as fromServices from './services';
import { PluralHttpUrlGenerator } from './entity';

@NgModule({
  imports: [EntityDataModule.forRoot(fromEntity.entityConfig)],
  declarations: [],
  providers: [
    { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
    {
      provide: DefaultDataServiceConfig,
      useValue: fromEntity.defaultDataServiceConfig
    },
    ...fromServices.entityDataServices
  ]
})
export class EntityStoreModule {
  constructor(
    entityDataService: EntityDataService,
    checksDataService: fromServices.ChecksDataService
  ) {
    // Register custom ngrx entity data service
    entityDataService.registerService('Check', checksDataService);
  }
}
