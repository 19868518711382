import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthError } from 'firebase/auth';
import { User, UserRole } from './auth.model';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    signInWithEmailAndPassword: props<{ email: string; password: string }>(),
    signInWithGoogle: emptyProps(),
    signInSuccess: emptyProps(),
    signInError: props<{ error: AuthError }>(),
    createUserWithEmailAndPassword: props<{ email: string; password: string }>(),
    createUserWithEmailAndPasswordSuccess: emptyProps(),
    createUserWithEmailAndPasswordError: props<{ error: AuthError }>(),
    signOut: props<{ redirect: boolean }>(),
    signOutSuccess: props<{ redirect: boolean }>(),
    signOutError: props<{ error: AuthError }>(),
    authStateChanged: props<{ user: User }>(),
    completeProfile: props<{ displayName: string; role: UserRole }>(),
    completeProfileSuccess: props<{ displayName: string; role: UserRole }>(),
    completeProfileError: props<{ error: AuthError }>(),
    loadRole: props<{ uid: string }>(),
    loadRoleSuccess: props<{ role: UserRole }>(),
    loadRoleError: props<{ error: AuthError }>(),
    updateProfile: props<{ displayName: string; photo?: File }>(),
    updateProfileSuccess: props<{ displayName: string; photo: string }>(),
    updateProfileError: props<{ error: AuthError }>()
  }
});
