import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserData } from './user-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserDataDataService {
  constructor(private http: HttpClient) {}

  save(data: UserData) {
    return this.http.post<void>('/api/users/data', data);
  }

  loadDatesWithData(year: number, month: number) {
    return this.http.get<string[]>(`/api/users/data/count/?year=${year}&month=${month}`);
  }

  loadDataByDate(date: Date) {
    return this.http.get<UserData>(`/api/users/data/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
  }
}
