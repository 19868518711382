import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultImg'
})
export class DefaultImgPipe implements PipeTransform {
  transform(imgUrl: string): string {
    return imgUrl ?? '/assets/images/profile/user-1.jpg';
  }
}
