import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../../store/core.state';
import { AuthState } from './auth.model';

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);

export const selectLoggedIn = createSelector(selectAuth, (state: AuthState) => !!state.user);

export const selectUser = createSelector(selectAuth, (state: AuthState) => {
  return state.user;
});

export const selectPhotoURL = createSelector(selectAuth, (state: AuthState) => {
  return state.user?.photoURL;
});

export const selectRole = createSelector(selectAuth, (state: AuthState) => {
  return state.role;
});

export const selectRoleLoading = createSelector(selectAuth, (state: AuthState) => {
  return state.roleLoading;
});

export const selectRoleLoaded = createSelector(selectAuth, (state: AuthState) => {
  return state.roleLoaded;
});
