import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME, UserDataState } from './user-data.model';

export const selectFeature = createFeatureSelector<UserDataState>(FEATURE_NAME);

export const selectDatesWithData = createSelector(selectFeature, (state) => {
  return state.datesWithData !== undefined ? state.datesWithData.map((d) => new Date(d + 'T00:00:00')) : undefined;
});

export const selectLoadingDatesWithData = createSelector(selectFeature, (state) => {
  return state.loadingDatesWithData;
});

export const selectLoadingDataByDate = createSelector(selectFeature, (state) => {
  return state.loadingDataByDate;
});

export const selectDataByDate = createSelector(selectFeature, (state) => {
  return state.dataByDate;
});
