import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PtcChartComponent } from './charts/ptc-columns-chart/ptc-chart.component';
import { PtcButtonToggleGroupComponent } from './ptc-button-toggle-group/ptc-button-toggle-group.component';
import { PtcTableComponent } from './ptc-table/ptc-table.component';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MaterialModule } from '../material.module';
import { PtcPageHeaderComponent } from './ptc-page-header/ptc-page-header.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { PtcBackButtonComponent } from './ptc-back-button/components/ptc-back-button/ptc-back-button.component';
import { PtcBackButtonContainerComponent } from './ptc-back-button/containers/ptc-back-button-container/ptc-back-button-container.component';
import { PtcFormWizardComponent } from './ptc-form-wizard/containers/ptc-form-wizard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PtcFormWizardRowComponent } from './ptc-form-wizard/components/ptc-form-wizard-row/ptc-form-wizard-row.component';
import { PtcImageUploaderComponent } from './ptc-form-wizard/components/ptc-image-uploader/ptc-image-uploader.component';
import { SafePipe } from 'safe-pipe';
import { DefaultImgPipe } from './pipes/default-img.pipe';

@NgModule({
  declarations: [
    PtcTableComponent,
    PtcButtonToggleGroupComponent,
    PtcChartComponent,
    PtcPageHeaderComponent,
    PtcBackButtonComponent,
    PtcBackButtonContainerComponent,
    PtcFormWizardComponent,
    PtcFormWizardRowComponent,
    PtcImageUploaderComponent,
    DefaultImgPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    NgApexchartsModule,
    TablerIconsModule.pick(TablerIcons),
    FormsModule,
    ReactiveFormsModule,
    SafePipe
  ],
  exports: [
    CommonModule,
    PtcTableComponent,
    PtcButtonToggleGroupComponent,
    PtcChartComponent,
    PtcPageHeaderComponent,
    PtcBackButtonContainerComponent,
    PtcFormWizardComponent,
    SafePipe,
    DefaultImgPipe
  ]
})
export class SharedModule {}
