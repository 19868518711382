import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { mergeMap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const angularFireAuth = inject(AngularFireAuth);

  return angularFireAuth.idToken.pipe(
    mergeMap((token: string) => {
      if (token) {
        req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
      }

      return next(req);
    })
  );
};
