import { NgModule } from '@angular/core';

import { RequestsRoutingModule } from './requests-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RequestsContainerComponent } from './containers/requests-container/requests-container.component';
import { MaterialModule } from 'src/app/material.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RequestEffects } from './store/request.effects';
import { FEATURE_NAME } from './store/request.model';
import { requestReducer } from './store/request.reducer';
import { RequestsMenuItemComponent } from './containers/requests-menu-item/requests-menu-item.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

@NgModule({
  declarations: [RequestsContainerComponent, RequestsMenuItemComponent],
  imports: [
    SharedModule,
    RequestsRoutingModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    StoreModule.forFeature(FEATURE_NAME, requestReducer),
    EffectsModule.forFeature([RequestEffects])
  ],
  exports: [RequestsMenuItemComponent]
})
export class RequestsModule {}
