import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RequestService } from '../../store/request.service';

@Component({
  selector: 'app-requests-menu-item',
  templateUrl: './requests-menu-item.component.html',
  styleUrl: './requests-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsMenuItemComponent implements OnInit {
  requestsCounter$ = this.requestService.requestsCounter$;

  constructor(private requestService: RequestService) {}

  ngOnInit(): void {
    this.requestService.loadRequestCounter();
  }
}
