import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestsContainerComponent } from './containers/requests-container/requests-container.component';
import { RequestService } from './store/request.service';

const routes: Routes = [
  {
    path: '',
    component: RequestsContainerComponent,
    canActivate: [RequestService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestsRoutingModule {}
