<div class="title-section">
  <h1>{{ title }}</h1>
  @if (buttonConfig) {
    <button mat-button class="bg-light-primary text-primary f-w-600" (click)="onClick()">
      @if (buttonConfig.icon) {
        <mat-icon class="d-flex align-items-center"><i-tabler [name]="buttonConfig.icon" class="icon-18 d-flex"></i-tabler></mat-icon>
      }
      @if (buttonConfig.label) {
        {{ buttonConfig.label }}
      }
    </button>
  }
</div>
