import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InsertDataFormComponent } from './containers/insert-data-form/insert-data-form.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserDataEffects } from './store/user-data.effects';
import { FEATURE_NAME } from './store/user-data.model';
import { userDataReducer } from './store/user-data.reducer';

@NgModule({
  declarations: [InsertDataFormComponent],
  imports: [
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    TablerIconsModule.pick(TablerIcons),
    StoreModule.forFeature(FEATURE_NAME, userDataReducer),
    EffectsModule.forFeature([UserDataEffects])
  ],
  exports: [InsertDataFormComponent]
})
export class UserDataModule {}
