import { Component } from '@angular/core';
import { navItems } from './sidebar-data';
import { NavService } from '../../../navigation/nav.service';
import { AuthenticationService } from 'src/app/core/auth/data/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  navItems = navItems;
  role$ = this.authService.role$;

  constructor(
    public navService: NavService,
    private authService: AuthenticationService
  ) {}
}
