export const FEATURE_NAME = 'user-data';

export interface UserDataState {
  error?: Error;
  loadingDatesWithData?: boolean;
  loadedDatesWithData?: boolean;
  errorDatesWithData?: Error;
  datesWithData: string[];
  loadingDataByDate?: boolean;
  loadedDataByDate?: boolean;
  dataByDate?: UserData;
  errorDataByDate?: Error;
}

export interface UserData {
  date: Date;
  steps: number;
  trained: boolean;
  weight: number;
  nutrition: {
    cheatDay: boolean;
    carbohydrates?: number;
    proteins?: number;
    fats?: number;
    calories?: number;
  };
}
