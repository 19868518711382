import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';

// icons
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

//Import all material modules
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Import Layouts
import { FullComponent } from './core/layouts/full/full.component';
import { BlankComponent } from './core/layouts/blank/blank.component';

// Vertical Layout
import { SidebarComponent } from './core/layouts/full/sidebar/sidebar.component';
import { HeaderComponent } from './core/layouts/full/header/header.component';
import { BrandingComponent } from './core/layouts/full/sidebar/branding.component';
import { AppNavItemComponent } from './core/layouts/full/sidebar/nav-item/nav-item.component';

// Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { firebaseConfig } from './configs/firebase.config';
import { AngularFireModule } from '@angular/fire/compat';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { RequestsModule } from './features/requests/requests.module';
import { SharedModule } from './shared/shared.module';
import { UserDataModule } from './features/user-data/user-data.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
registerLocaleData(localeIt);

@NgModule({
  declarations: [AppComponent, FullComponent, BlankComponent, SidebarComponent, HeaderComponent, BrandingComponent, AppNavItemComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    AngularFireModule.initializeApp(firebaseConfig),
    RequestsModule,
    UserDataModule,
    SharedModule
  ],
  exports: [TablerIconsModule],
  bootstrap: [AppComponent],
  providers: [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: MAT_DATE_LOCALE, useValue: 'it' }
  ]
})
export class AppModule {}
