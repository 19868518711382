import { UserRole } from 'src/app/core/auth/data/auth.model';
import { NavItem } from './nav-item/nav-item';

export const PTSidebarNavItems: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '/dashboard',
    roles: [UserRole.PT]
  },
  {
    displayName: 'Collaborazioni',
    iconName: 'users',
    route: '/collaborations',
    roles: [UserRole.PT]
  }
];
