import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { mergeMap, of, skipWhile } from 'rxjs';
import { AuthenticationService } from '../auth/data/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CompleteUserGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate() {
    return this.authenticationService.user$.pipe(
      mergeMap((user) => {
        if (user) {
          if (!user.displayName) {
            return this.router.navigate(['/complete-profile']);
          }

          return this.authenticationService.roleLoaded$.pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() => this.authenticationService.role$),
            mergeMap((role) => {
              if (!role) {
                return this.router.navigate(['/complete-profile']);
              } else {
                return of(true);
              }
            })
          );
        }

        return this.router.navigate(['/authentication/login']);
      })
    );
  }
}
