/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Action, createReducer, on } from '@ngrx/store';
import { RequestsState } from './request.model';
import { RequestActions } from './request.actions';
import { produce } from 'immer';

export const initialState: RequestsState = {
  loading: false,
  loaded: undefined,
  requests: undefined,
  error: undefined,
  requestsCounter: undefined
};

export const requestReducer = (state: RequestsState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(RequestActions.getAll, (state) => ({
    ...state,
    loading: true,
    loaded: undefined,
    customers: undefined
  })),
  on(RequestActions.getAllSuccess, (state, { requests }) => ({
    ...state,
    loading: false,
    loaded: true,
    requests: requests
  })),
  on(RequestActions.getAllError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: error
  })),
  on(
    RequestActions.acceptSuccess,
    produce((state, { id }) => {
      state.requests.splice(state.requests.findIndex((x) => x.id === id));
    })
  ),
  on(
    RequestActions.denySuccess,
    produce((state, { id }) => {
      state.requests.splice(state.requests.findIndex((x) => x.id === id));
    })
  ),
  on(RequestActions.loadRequestsCounterSuccess, (state, { counter }) => ({
    ...state,
    requestsCounter: counter
  }))
);
