import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastService: ToastrService) {}

  success(msg?: string, title?: string) {
    this.toastService.success(msg, title);
  }

  warning(msg?: string, title?: string) {
    this.toastService.warning(msg, title);
  }

  info(msg?: string, title?: string) {
    this.toastService.info(msg, title);
  }

  error(msg?: string, title?: string) {
    this.toastService.error(msg, title);
  }
}
