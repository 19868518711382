import { Component } from '@angular/core';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class BlankComponent {
  constructor() {}
}
