import { Injectable } from '@angular/core';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { defer, from, mergeMap, of } from 'rxjs';
import { UserRole } from './auth.model';
import { NotificationService } from '../../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private notificationService: NotificationService
  ) {}

  signInWithGoogle() {
    return defer(() => from(this.afAuth.signInWithPopup(new GoogleAuthProvider())));
  }

  signIn(email: string, password: string) {
    return defer(() => from(this.afAuth.signInWithEmailAndPassword(email, password)));
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    return defer(() => from(this.afAuth.createUserWithEmailAndPassword(email, password)));
  }

  signOut() {
    return defer(() => from(this.afAuth.signOut()));
  }

  updateProfile(displayName: string, photoURL?: string) {
    return this.afAuth.user.pipe(
      mergeMap(async (user) => {
        await user.updateProfile({
          displayName: displayName,
          photoURL: photoURL || null
        });
        return user;
      })
    );
  }

  updateRole(uid: string, role: UserRole) {
    return defer(() => from(this.afs.collection('users').doc(uid).set({ role: role })));
  }

  getRole(uid: string) {
    return this.afs
      .collection('users')
      .doc(uid)
      .get()
      .pipe(
        mergeMap((userDoc) => {
          if (userDoc.exists) {
            const docRole = userDoc.data()?.['role'];
            if (docRole == 'PT' || docRole == 'customer') {
              const role = docRole as UserRole;
              return of(role);
            }
            return of(null);
          } else {
            return of(null);
          }
        })
      );
  }

  manageFirebaseAuthError(errorCode: string) {
    switch (errorCode) {
      case 'auth/invalid-email':
        this.notificationService.error('La email inserita non è valida.');
        break;

      case 'auth/invalid-credential':
        this.notificationService.error('Le credenziali inserite non sono valide.');
        break;

      case 'auth/weak-password':
        this.notificationService.error('La password inserita è troppo debole.');
        break;

      case 'auth/email-already-in-use':
        this.notificationService.error('La email inserita è già utilizzata.');
        break;

      case 'auth/popup-closed-by-user':
      case 'auth/cancelled-popup-request':
        break;

      default:
        this.notificationService.error('Ops. Qualcosa sembra essere andato storto.');
        break;
    }
  }
}
