import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Request } from './request.model';

@Injectable({
  providedIn: 'root'
})
export class RequestDataService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Request[]>('/api/collaborations/requests/');
  }

  getRequestsCounter() {
    return this.http.get<number>('/api/collaborations/requests-counter');
  }

  accept(id: number) {
    return this.http.put<void>(`/api/collaborations/${id}/accept`, {});
  }

  deny(id: number) {
    return this.http.put<void>(`/api/collaborations/${id}/deny`, {});
  }
}
