import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.model';
import { AuthActions } from './auth.actions';

export const initialState: AuthState = {
  user: undefined,
  role: undefined,
  roleLoaded: false,
  roleLoading: false
};

export const authReducer = (state: AuthState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(AuthActions.authStateChanged, (state, { user }) => {
    return {
      ...state,
      user: user
    };
  }),
  on(AuthActions.signOutSuccess, () => initialState),
  on(AuthActions.loadRole, (state) => {
    return {
      ...state,
      roleLoading: true
    };
  }),
  on(AuthActions.loadRoleSuccess, (state, { role }) => {
    return {
      ...state,
      role: role,
      roleLoading: false,
      roleLoaded: true
    };
  }),
  on(AuthActions.completeProfileSuccess, (state, { displayName, role }) => {
    return {
      ...state,
      role: role,
      user: {
        ...state.user,
        displayName: displayName
      }
    };
  }),
  on(AuthActions.updateProfileSuccess, (state, { displayName, photo }) => {
    return {
      ...state,
      user: {
        ...state.user,
        displayName: displayName,
        photoURL: photo
      }
    };
  })
);
