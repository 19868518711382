import { WeekDay } from '@angular/common';
import { CheckFrequency } from '../../checks/store/check.model';
import { SubscriptionType } from '../../collaborations/store/collaborations.model';

export const FEATURE_NAME = 'requests';

export interface RequestsState {
  loading?: boolean;
  loaded?: boolean;
  requests?: Request[];
  requestsCounter?: number;
  error?: Error;
}

export interface Request {
  id: number;
  ptName: string;
  ptImagePath: string;
  checkFrequency: CheckFrequency;
  checkDay: WeekDay;
  subscription: SubscriptionType;
  type: RequestType;
}

export enum RequestType {
  New = 'New',
  Renew = 'Renew'
}
