<ng-container>
  <mat-form-field *ngIf="tableConfiguration.filter.show" appearance="outline" class="m-t-4">
    <mat-label>{{ tableConfiguration.filter.label }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" [placeholder]="tableConfiguration.filter.placeholder" #input />
  </mat-form-field>
  <table mat-table [dataSource]="matDataSource" class="w-100">
    <ng-container *ngFor="let column of tableConfiguration.columns">
      <ng-container [matColumnDef]="column.columnId">
        <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
          <ng-container *ngIf="column.type !== 'image'">
            {{ column.label }}
          </ng-container>
        </th>

        <td mat-cell *matCellDef="let element">
          <ng-container #imageColumn *ngIf="column.type === 'user-image'">
            <img [src]="element[column.dataSourceProperty!] | defaultImg" width="35" height="35" class="rounded-circle" />
          </ng-container>

          <ng-container #imageColumn *ngIf="column.type === 'image'">
            <img [src]="element[column.dataSourceProperty!]" width="35" height="35" class="rounded-circle" />
          </ng-container>

          <ng-container #textColumn *ngIf="column.type === 'text'">
            {{ element[column.dataSourceProperty!] || (column.defaultValue ? column.defaultValue : null) }}
          </ng-container>

          <ng-container #textColumn *ngIf="column.type === 'date'">
            {{ element[column.dataSourceProperty!] | date: 'dd/MM/yyyy' }}
          </ng-container>

          @if (column.type === 'switch') {
            @for (switchOption of column.switchOptions; track switchOption) {
              @if (element[column.dataSourceProperty!] === switchOption.value) {
                <span [ngClass]="[switchOption.classes, 'rounded', 'f-w-600', 'p-6', 'p-y-4', 'f-s-12']">
                  {{ switchOption.label }}
                </span>
              }
            }
          }

          <ng-container *ngIf="column.type === 'buttons'">
            @for (button of column.buttonOptions.buttons; track button) {
              @if (button.actionType === 'link') {
                <a
                  mat-button
                  [ngClass]="[
                    button.backgroundColorClass ?? 'bg-light-primary',
                    button.textColorClass ?? 'text-primary',
                    'f-w-600',
                    'm-l-4'
                  ]"
                  [routerLink]="button.getRouterLink(element)"
                  [disabled]="button.disable ? button.disable(element) : false"
                  [hidden]="button.hide ? button.hide(element) : false"
                >
                  {{ button.label }}
                </a>
              } @else if (button.actionType === 'click') {
                <button
                  mat-button
                  [ngClass]="[
                    button.backgroundColorClass ?? 'bg-light-primary',
                    button.textColorClass ?? 'text-primary',
                    'f-w-600',
                    'm-l-4'
                  ]"
                  (click)="this.buttonClick.emit({ action: button.id, row: element })"
                  [disabled]="button.disable ? button.disable(element) : false"
                  [hidden]="button.hide ? button.hide(element) : false"
                >
                  {{ button.label }}
                </button>
              }
            }
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableConfiguration.displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableConfiguration.displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="tableConfiguration.paginator.pageSizeOptions" aria-label="Select page"></mat-paginator>
</ng-container>
