import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserData } from './user-data.model';

export const UserDataActions = createActionGroup({
  source: 'UserData',
  events: {
    save: props<{ data: UserData }>(),
    saveSuccess: emptyProps(),
    saveError: props<{ error: Error }>(),
    loadDatesWithData: props<{ year: number; month: number }>(),
    loadDatesWithDataSuccess: props<{ dates: string[] }>(),
    loadDatesWithDataError: props<{ error: Error }>(),
    loadDataByDate: props<{ date: Date }>(),
    loadDataByDateSuccess: props<{ data: UserData }>(),
    loadDataByDateError: props<{ error: Error }>()
  }
});
