<mat-sidenav-container class="mainWrapper blue_theme light-theme" autosize autoFocus dir="ltr">
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->

  <mat-sidenav
    #leftsidenav
    [mode]="isOver ? 'over' : 'side'"
    [opened]="!isOver"
    class="sidebarNav"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper">
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->

    <app-header [showToggle]="!isOver" (toggleMobileNav)="sidenav.toggle()"></app-header>

    <main class="pageWrapper">
      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
