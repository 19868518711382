import { Injectable } from '@angular/core';
import { DefaultHttpUrlGenerator, HttpResourceUrls, normalizeRoot, Pluralizer } from '@ngrx/data';
import { camelCaseToKebab } from 'camelcase-to-kebab';

// From https://ngrx.io/guide/data/extension-points

@Injectable()
export class PluralHttpUrlGenerator extends DefaultHttpUrlGenerator {
  constructor(private pluralizer2: Pluralizer) {
    super(pluralizer2);
  }

  protected getResourceUrls(entityName: string, root: string): HttpResourceUrls {
    let resourceUrls = this.knownHttpResourceUrls[entityName];
    if (!resourceUrls) {
      const nRoot = normalizeRoot(root);
      const url = `${nRoot}/${this.getUrl(entityName)}/`.toLowerCase();
      resourceUrls = {
        entityResourceUrl: url,
        collectionResourceUrl: url
      };
      this.registerHttpResourceUrls({ [entityName]: resourceUrls });
    }
    return resourceUrls;
  }

  private getUrl(entityName: string) {
    const url = camelCaseToKebab(this.pluralizer2.pluralize(entityName));
    if (url.at(0) === '-') {
      return url.slice(1);
    }
    return url;
  }
}
