import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;

  //get options from service
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;

  get isOver(): boolean {
    return this.isMobileScreen;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.layoutChangesSubscription = this.breakpointObserver.observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW]).subscribe((state) => {
      // SidenavOpened must be reset true when layout changes

      this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }
}
