import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { PtcTableConfiguration } from './ptc-table.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ptc-table',
  templateUrl: './ptc-table.component.html',
  styleUrl: './ptc-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcTableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() dataSource: T[] = [];
  matDataSource: MatTableDataSource<T>;

  @Input() tableConfiguration: PtcTableConfiguration<T> = {
    displayedColumns: [],
    columns: [],
    paginator: {
      pageSizeOptions: []
    },
    filter: {
      show: false
    }
  };

  @Output() buttonClick = new EventEmitter<{ action: string; row: T }>();

  ngOnInit() {
    this.refreshTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.refreshTable();
    }
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  private refreshTable() {
    this.matDataSource = new MatTableDataSource(this.dataSource);
  }
}
