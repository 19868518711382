import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserDataService } from '../../store/user-data.service';
import { UserData } from '../../store/user-data.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';

@Component({
  selector: 'app-insert-data-form',
  templateUrl: './insert-data-form.component.html',
  styleUrl: './insert-data-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertDataFormComponent implements OnInit {
  datesWithData: Date[] = [];
  datesWithData$: Observable<Date[]> = this.userDataService.datesWithData$;

  loadingDatesWithData: boolean;
  loadingDatesWithData$: Observable<boolean> = this.userDataService.loadingDatesWithData$;

  step = 0;

  steps = [
    {
      label: 'Giorno',
      formGroupName: 'dateForm'
    },
    {
      label: 'Passi',
      formGroupName: 'stepsForm'
    },
    {
      label: 'Allenamento',
      formGroupName: 'trainingForm'
    },
    {
      label: 'Peso',
      formGroupName: 'weightForm'
    },
    {
      label: 'Nutrizione',
      formGroupName: 'nutritionForm'
    }
  ];

  dataForm = new FormGroup({
    dateForm: new FormGroup({
      selectedDate: new FormControl('', Validators.required)
    }),
    stepsForm: new FormGroup({
      steps: new FormControl(undefined, Validators.required)
    }),
    trainingForm: new FormGroup({
      trained: new FormControl(false, Validators.required)
    }),
    weightForm: new FormGroup({
      weight: new FormControl(undefined, Validators.required)
    }),
    nutritionForm: new FormGroup({
      cheatDay: new FormControl(false, Validators.required),
      carbohydrates: new FormControl(undefined, Validators.required),
      proteins: new FormControl(undefined, Validators.required),
      fats: new FormControl(undefined, Validators.required),
      calories: new FormControl(undefined, Validators.required)
    })
  });

  constructor(private userDataService: UserDataService) {
    this.getFormGroup('nutritionForm')
      .get('cheatDay')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe((isCheatDay: boolean) => {
        if (isCheatDay) {
          this.getFormGroup('nutritionForm').controls['carbohydrates'].clearValidators();
          this.getFormGroup('nutritionForm').controls['carbohydrates'].disable();
          this.getFormGroup('nutritionForm').controls['proteins'].clearValidators();
          this.getFormGroup('nutritionForm').controls['proteins'].disable();
          this.getFormGroup('nutritionForm').controls['fats'].clearValidators();
          this.getFormGroup('nutritionForm').controls['fats'].disable();
          this.getFormGroup('nutritionForm').controls['calories'].clearValidators();
          this.getFormGroup('nutritionForm').controls['calories'].disable();
        } else {
          this.getFormGroup('nutritionForm').controls['carbohydrates'].setValidators([Validators.required]);
          this.getFormGroup('nutritionForm').controls['carbohydrates'].enable();
          this.getFormGroup('nutritionForm').controls['proteins'].setValidators([Validators.required]);
          this.getFormGroup('nutritionForm').controls['proteins'].enable();
          this.getFormGroup('nutritionForm').controls['fats'].setValidators([Validators.required]);
          this.getFormGroup('nutritionForm').controls['fats'].enable();
          this.getFormGroup('nutritionForm').controls['calories'].setValidators([Validators.required]);
          this.getFormGroup('nutritionForm').controls['calories'].enable();
        }
        this.getFormGroup('nutritionForm').controls['carbohydrates'].updateValueAndValidity();
        this.getFormGroup('nutritionForm').controls['proteins'].updateValueAndValidity();
        this.getFormGroup('nutritionForm').controls['fats'].updateValueAndValidity();
        this.getFormGroup('nutritionForm').controls['calories'].updateValueAndValidity();
      });

    this.userDataService.datesWithData$.pipe(takeUntilDestroyed()).subscribe((d) => (this.datesWithData = d));
    this.userDataService.loadingDatesWithData$.pipe(takeUntilDestroyed()).subscribe((d) => (this.loadingDatesWithData = d));
    combineLatest([this.userDataService.dataByDate$, this.userDataService.loadingDataByDate$])
      .pipe(takeUntilDestroyed())
      .subscribe(([data, loading]) => {
        if (loading === false && data != undefined) {
          this.updateFormData(data);
        }
      });
  }

  updateFormData(data: UserData): void {
    this.getFormGroup('stepsForm').controls['steps'].setValue(data.steps);
    this.getFormGroup('trainingForm').controls['trained'].setValue(data.trained);
    this.getFormGroup('weightForm').controls['weight'].setValue(data.weight);
    this.getFormGroup('nutritionForm').controls['cheatDay'].setValue(data.nutrition.cheatDay);
    this.getFormGroup('nutritionForm').controls['carbohydrates'].setValue(data.nutrition.carbohydrates);
    this.getFormGroup('nutritionForm').controls['proteins'].setValue(data.nutrition.proteins);
    this.getFormGroup('nutritionForm').controls['fats'].setValue(data.nutrition.fats);
    this.getFormGroup('nutritionForm').controls['calories'].setValue(data.nutrition.calories);
  }

  ngOnInit(): void {
    this.userDataService.loadDatesWithData();
  }

  monthSelected(selectedDate: Date) {
    this.userDataService.loadDatesWithData(selectedDate.getFullYear(), selectedDate.getMonth() + 1);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (!this.loadingDatesWithData && this.datesWithData) {
      if (view === 'month') {
        return this.datesWithData.find(
          (d) => d.getFullYear() == cellDate.getFullYear() && d.getMonth() == cellDate.getMonth() && d.getDate() == cellDate.getDate()
        )
          ? 'busy-date'
          : '';
      }
    }

    return '';
  };

  getFormGroup(name: string) {
    return this.dataForm.get(name) as FormGroup;
  }

  updateFormDate(value: Date) {
    this.getFormGroup('dateForm').get('selectedDate').setValue(value);
    this.userDataService.loadDataByDate(value);
  }

  dateCanBeSelected(date: Date) {
    if (this.loadingDatesWithData) {
      return false;
    }
    return date <= new Date();
  }

  canGoNext() {
    for (let i = 0; i <= this.step; i++) {
      const step = this.steps[i];

      if (!this.getFormGroup(step.formGroupName).valid) {
        return false;
      }
    }
    return true;
  }

  save() {
    if (this.dataForm.valid) {
      const isCheatDay = this.getFormGroup('nutritionForm').get('cheatDay').value;

      const data: UserData = {
        date: new Date(this.getFormGroup('dateForm').get('selectedDate').value as Date),
        steps: this.getFormGroup('stepsForm').get('steps').value,
        trained: this.getFormGroup('trainingForm').get('trained').value,
        weight: this.getFormGroup('weightForm').get('weight').value,
        nutrition: {
          cheatDay: isCheatDay,
          carbohydrates: !isCheatDay ? this.getFormGroup('nutritionForm').get('carbohydrates').value : null,
          proteins: !isCheatDay ? this.getFormGroup('nutritionForm').get('proteins').value : null,
          fats: !isCheatDay ? this.getFormGroup('nutritionForm').get('fats').value : null,
          calories: !isCheatDay ? this.getFormGroup('nutritionForm').get('calories').value : null
        }
      };

      this.userDataService.save(data);
    }
  }
}
