import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { RequestActions } from './request.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/core.state';
import { selectRequests, selectRequestsCounter } from './request.selectors';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  readonly requests$ = this.store.select(selectRequests);
  readonly requestsCounter$ = this.store.select(selectRequestsCounter);

  constructor(private store: Store) {}

  canActivate() {
    return (<Store<AppState>>(<unknown>this.store)).pipe(
      tap(() => {
        this.store.dispatch(RequestActions.getAll());
      })
    );
  }

  accept(id: number) {
    this.store.dispatch(RequestActions.accept({ id: id }));
  }

  deny(id: number) {
    this.store.dispatch(RequestActions.deny({ id: id }));
  }

  loadRequestCounter() {
    this.store.dispatch(RequestActions.loadRequestsCounter());
  }
}
