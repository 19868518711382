import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Request } from './request.model';

export const RequestActions = createActionGroup({
  source: 'Request',
  events: {
    getAll: emptyProps(),
    getAllSuccess: props<{ requests: Request[] }>(),
    getAllError: props<{ error: Error }>(),
    accept: props<{ id: number }>(),
    acceptSuccess: props<{ id: number }>(),
    acceptError: props<{ error: Error }>(),
    deny: props<{ id: number }>(),
    denySuccess: props<{ id: number }>(),
    denyError: props<{ error: Error }>(),
    loadRequestsCounter: emptyProps(),
    loadRequestsCounterSuccess: props<{ counter: number }>(),
    loadRequestsCounterError: props<{ error: Error }>()
  }
});
