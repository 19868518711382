export interface CustomerCheck {
  id: number;
  date: Date;
  averageWeight?: number;
  averageSteps?: number;
  status: CheckStatus;
  dataLoaded: boolean;
}

export enum CheckStatus {
  Locked = 'Locked',
  Running = 'Running',
  Completed = 'Completed'
}

export enum CheckFrequency {
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly'
}
