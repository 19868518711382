import { Injectable } from '@angular/core';
import { UserRole } from './auth.model';
import { select, Store } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { selectLoggedIn, selectPhotoURL, selectRole, selectRoleLoaded, selectRoleLoading, selectUser } from './auth.selectors';
import firebase from 'firebase/compat';
import { mapFirebaseUser } from './auth.mapper';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loggedIn$ = this.store.pipe(select(selectLoggedIn));
  user$ = this.store.pipe(select(selectUser));
  photoURL$ = this.store.pipe(select(selectPhotoURL));
  role$ = this.store.pipe(select(selectRole));
  roleLoading$ = this.store.pipe(select(selectRoleLoading));
  roleLoaded$ = this.store.pipe(select(selectRoleLoaded));

  constructor(private store: Store) {}

  signInWithGoogle() {
    this.store.dispatch(AuthActions.signInWithGoogle());
  }

  signIn(email: string, password: string) {
    this.store.dispatch(AuthActions.signInWithEmailAndPassword({ email: email, password: password }));
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    this.store.dispatch(AuthActions.createUserWithEmailAndPassword({ email: email, password: password }));
  }

  signOut(redirect: boolean = true) {
    this.store.dispatch(AuthActions.signOut({ redirect: redirect }));
  }

  completeProfile(displayName: string, role: UserRole) {
    this.store.dispatch(AuthActions.completeProfile({ displayName: displayName, role: role }));
  }

  updateProfile(displayName: string, photo?: File) {
    this.store.dispatch(AuthActions.updateProfile({ displayName: displayName, photo: photo }));
  }

  stateChanged(user: firebase.User) {
    this.store.dispatch(AuthActions.authStateChanged({ user: user ? mapFirebaseUser(user) : undefined }));
    if (user) {
      this.store.dispatch(AuthActions.loadRole({ uid: user.uid }));
    }
  }
}
