import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator } from '@ngrx/data';
import { CustomerCheck } from './check.model';

@Injectable({
  providedIn: 'root'
})
export class ChecksDataService extends DefaultDataService<CustomerCheck> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, config?: DefaultDataServiceConfig) {
    super('Check', http, httpUrlGenerator, config);
  }
}
