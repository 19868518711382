import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME, RequestsState } from './request.model';

export const selectFeature = createFeatureSelector<RequestsState>(FEATURE_NAME);

export const selectRequests = createSelector(selectFeature, (state) => {
  return state.requests;
});

export const selectRequestsCounter = createSelector(selectFeature, (state) => {
  return state.requestsCounter;
});
