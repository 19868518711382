import { Injectable } from '@angular/core';
import { UserDataActions } from './user-data.actions';
import { Store } from '@ngrx/store';
import { UserData } from './user-data.model';
import { Observable } from 'rxjs';
import { selectDataByDate, selectDatesWithData, selectLoadingDataByDate, selectLoadingDatesWithData } from './user-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  readonly datesWithData$: Observable<Date[]> = this.store.select(selectDatesWithData);
  readonly loadingDatesWithData$: Observable<boolean> = this.store.select(selectLoadingDatesWithData);

  readonly dataByDate$: Observable<UserData> = this.store.select(selectDataByDate);
  readonly loadingDataByDate$: Observable<boolean> = this.store.select(selectLoadingDataByDate);

  constructor(private store: Store) {}

  save(data: UserData) {
    this.store.dispatch(UserDataActions.save({ data: data }));
  }

  loadDatesWithData(year?: number, month?: number) {
    this.store.dispatch(
      UserDataActions.loadDatesWithData({
        year: year ?? new Date().getFullYear(),
        month: month ?? new Date().getMonth() + 1
      })
    );
  }

  loadDataByDate(date: Date) {
    this.store.dispatch(
      UserDataActions.loadDataByDate({
        date: date
      })
    );
  }
}
