import { PtcTableConfiguration } from 'src/app/shared/ptc-table/ptc-table.model';
import { Request, RequestType } from '../../store/request.model';
import { CheckFrequency } from 'src/app/features/checks/store/check.model';
import { WeekDay } from '@angular/common';

export const RequestsTableConfiguration: PtcTableConfiguration<Request> = {
  displayedColumns: ['ptImg', 'ptName', 'checkDay', 'checkFrequency', 'type', 'actions'],
  columns: [
    {
      columnId: 'ptImg',
      dataSourceProperty: 'ptImagePath',
      type: 'user-image'
    },
    {
      columnId: 'ptName',
      dataSourceProperty: 'ptName',
      type: 'text',
      label: 'PT'
    },
    {
      columnId: 'checkDay',
      dataSourceProperty: 'checkDay',
      type: 'switch',
      label: 'Giorno del check',
      switchOptions: [
        { value: 'Monday', label: 'Lunedì', classes: 'bg-light-primary text-primary' },
        { value: 'Tuesday', label: 'Martedì', classes: 'bg-light-primary text-primary' },
        { value: 'Wednesday', label: 'Mercoledì', classes: 'bg-light-primary text-primary' },
        { value: 'Thursday', label: 'Giovedì', classes: 'bg-light-primary text-primary' },
        { value: 'Friday', label: 'Venerdì', classes: 'bg-light-primary text-primary' },
        { value: 'Saturday', label: 'Sabato', classes: 'bg-light-primary text-primary' },
        { value: 'Sunday', label: 'Domenica', classes: 'bg-light-primary text-primary' }
      ]
    },
    {
      columnId: 'checkFrequency',
      dataSourceProperty: 'checkFrequency',
      type: 'switch',
      label: 'Frequenza check',
      switchOptions: [
        { value: CheckFrequency.Weekly, label: 'Settimanale', classes: 'bg-light-warning text-warning' },
        { value: CheckFrequency.Biweekly, label: 'Ogni due settimane', classes: 'bg-light-primary text-primary' },
        { value: CheckFrequency.Monthly, label: 'Ogni quattro settimane', classes: 'bg-light-accent text-accent' }
      ]
    },
    {
      columnId: 'type',
      dataSourceProperty: 'type',
      type: 'switch',
      label: 'Tipo',
      switchOptions: [
        { value: RequestType.New, label: 'Nuova collaborazione', classes: 'bg-light-primary text-primary' },
        { value: RequestType.Renew, label: 'Rinnovo collaborazione', classes: 'bg-light-accent text-accent' }
      ]
    },
    {
      columnId: 'actions',
      type: 'buttons',
      buttonOptions: {
        buttons: [
          {
            id: 'accept',
            label: 'Accetta',
            actionType: 'click',
            getRouterLink(dataRow: Request): string[] {
              return [`${dataRow.id}`];
            }
          },
          {
            id: 'deny',
            label: 'Rifiuta',
            actionType: 'click',
            backgroundColorClass: 'bg-light-error',
            textColorClass: 'text-error',
            getRouterLink(dataRow: Request): string[] {
              return [`${dataRow.id}`];
            }
          }
        ]
      }
    }
  ],
  paginator: { pageSizeOptions: [5, 10, 20] },
  filter: {
    show: true,
    label: 'Ricerca',
    placeholder: 'Nome e/o cognome'
  }
};
