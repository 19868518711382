<mat-toolbar class="topbar">
  <!-- Mobile Menu -->
  <button mat-icon-button (click)="toggleMobileNav.emit()" *ngIf="!showToggle">
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <ng-container *ngIf="user$ | async as user">
    @if ((role$ | async) === UserRole.Customer) {
      <button mat-flat-button color="primary" (click)="showDataForm()">Inserisci i dati</button>
      <app-requests-menu-item></app-requests-menu-item>
    }
    <button mat-icon-button [matMenuTriggerFor]="profilemenu" aria-label="Notifications">
      <img referrerpolicy="no-referrer" [src]="photoURL$ | async | defaultImg" class="rounded-circle object-cover" width="35" />
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
      <div mat-menu-item>{{ user.displayName }}</div>
      <a mat-menu-item [routerLink]="['/user-profile']">
        <mat-icon class="d-flex align-items-center"><i-tabler name="user" class="icon-18 d-flex"></i-tabler></mat-icon>
        Profilo
      </a>
      <div class="p-x-12 m-t-12">
        <button (click)="logout()" mat-stroked-button color="primary" class="w-100">Logout</button>
      </div>
    </mat-menu>
  </ng-container>
</mat-toolbar>

<ng-template #dataTemplate>
  <h2 mat-dialog-title>Registra i tuoi dati</h2>
  <mat-dialog-content>
    <app-insert-data-form></app-insert-data-form>
  </mat-dialog-content>
</ng-template>
