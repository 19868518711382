import { Injectable } from '@angular/core';
import { AuthDataService } from './auth-data.service';
import { AuthActions } from './auth.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, finalize, mergeMap, Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthError } from 'firebase/auth';
import { Store } from '@ngrx/store';
import { selectUser } from './auth.selectors';
import { NotificationService } from '../../notification/notification.service';
import { getGuid } from '@ngrx/data';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authDataService: AuthDataService,
    private router: Router,
    private store: Store,
    private notificationService: NotificationService,
    private storage: AngularFireStorage
  ) {}

  loadRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadRole),
      mergeMap((action) =>
        this.authDataService.getRole(action.uid).pipe(
          map((role) => AuthActions.loadRoleSuccess({ role: role })),
          catchError((error: AuthError) => of(AuthActions.loadRoleError({ error: error })))
        )
      )
    )
  );

  signInWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInWithEmailAndPassword),
      mergeMap((action) =>
        this.authDataService.signIn(action.email, action.password).pipe(
          map(() => AuthActions.signInSuccess()),
          catchError((error: AuthError) => of(AuthActions.signInError({ error: error })))
        )
      )
    )
  );

  signInWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInWithGoogle),
      mergeMap(() =>
        this.authDataService.signInWithGoogle().pipe(
          map(() => AuthActions.signInSuccess()),
          catchError((error: AuthError) => of(AuthActions.signInError({ error: error })))
        )
      )
    )
  );

  signInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signInSuccess),
        tap(() => void this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  signInError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signInError),
        tap((action) => this.authDataService.manageFirebaseAuthError(action.error.code))
      ),
    { dispatch: false }
  );

  createUserWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.createUserWithEmailAndPassword),
      mergeMap((action) =>
        this.authDataService.createUserWithEmailAndPassword(action.email, action.password).pipe(
          map(() => AuthActions.createUserWithEmailAndPasswordSuccess()),
          catchError((error: AuthError) => of(AuthActions.createUserWithEmailAndPasswordError({ error: error })))
        )
      )
    )
  );

  createUserWithEmailAndPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.createUserWithEmailAndPasswordSuccess),
        tap(() => void this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  createUserWithEmailAndPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.createUserWithEmailAndPasswordError),
        tap((action) => this.authDataService.manageFirebaseAuthError(action.error.code))
      ),
    { dispatch: false }
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      mergeMap((action) =>
        this.authDataService.signOut().pipe(
          map(() => AuthActions.signOutSuccess({ redirect: action.redirect })),
          catchError((error: AuthError) => of(AuthActions.signOutError({ error: error })))
        )
      )
    )
  );

  signOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signOutSuccess),
        tap((action) => (action.redirect ? void this.router.navigate(['/authentication/login']) : null))
      ),
    { dispatch: false }
  );

  signOutError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signOutError),
        tap((action) => this.authDataService.manageFirebaseAuthError(action.error.code))
      ),
    { dispatch: false }
  );

  completeProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.completeProfile),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([action, user]) =>
        this.authDataService.updateProfile(action.displayName).pipe(
          mergeMap(() => this.authDataService.updateRole(user.uid, action.role)),
          map(() => AuthActions.completeProfileSuccess({ displayName: action.displayName, role: action.role })),
          catchError((error: AuthError) => of(AuthActions.completeProfileError({ error: error })))
        )
      )
    )
  );

  completeProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.completeProfileSuccess),
        tap(() => void this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  completeProfileError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.completeProfileError),
        tap((action) => this.authDataService.manageFirebaseAuthError(action.error.code))
      ),
    { dispatch: false }
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateProfile),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([action, user]) =>
        this.uploadImage(user.uid, action.photo).pipe(
          mergeMap((photo) => {
            return this.authDataService
              .updateProfile(action.displayName, photo)
              .pipe(map(() => AuthActions.updateProfileSuccess({ displayName: action.displayName, photo: photo })));
          }),
          catchError((error: AuthError) => of(AuthActions.updateProfileError({ error: error })))
        )
      )
    )
  );

  updateProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateProfileSuccess),
        tap(() => void this.notificationService.success('Aggiornamento avvenuto con successo'))
      ),
    { dispatch: false }
  );

  updateProfileError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateProfileError),
        tap((action) => this.authDataService.manageFirebaseAuthError(action.error.code))
      ),
    { dispatch: false }
  );

  private uploadImage(uid: string, file: File): Observable<string> {
    if (file) {
      const filePath = `/users/${uid}/photoprofile/${getGuid()}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      const uploadObservable = new Observable<string>((observer) => {
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                observer.next(url as string);
                observer.complete();
              });
            })
          )
          .subscribe();
      });

      return uploadObservable;
    }
    return of('' as string);
  }
}
